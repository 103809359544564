<template>
<div>
  <!--begin::Dashboard-->
  <h3>Hello, admin</h3>
  <!--end::Dashboard-->
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'Dashboard',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Dashboard' }])
  },
}
</script>
